import {
    Tabs,
    TabsHeader,
    TabsBody,
    Tab,
    TabPanel,
} from "@material-tailwind/react";
import { AnimatePresence } from "framer-motion";
import { Fragment, useContext} from "react";
import { AppContext } from "../AppContext";
import Carousel from "../components/common/Carousel";
import { BACKEND_HOST } from "../constants/Env";
export default function Products(){
    const {
        appData,
        appDetailData
    } = useContext(AppContext);
    const {products} = appDetailData;
    const sectionData = appData.sections;
    const sectionTitle = sectionData? sectionData[1].title : "";
    const sectionID = sectionData? sectionData[1].href:"";
    return (
        <AnimatePresence key="product">
            <div className="shape-product-t h-44 lg:h-36"/>
            <div className="bg-gray-500 text-white py-10" id={sectionID}>
                <div className="text-4xl font-bold mb-3 text-center">{sectionTitle}</div>
                <div className="mx-auto h-1.5 w-12 bg-white mb-10"/>
                <div className="overflow-auto">
                    <div className="px-3 lg:px-10 min-w-[1000px]">
                        {/* <p className="text-center">
                            <ReactMarkdown>{sectionDesc}</ReactMarkdown>
                        </p> */}
                        {
                            !products ? null:
                            <Tabs value={products[0].name}>
                                <TabsHeader className="bg-gray-400 items-center">
                                    {products.map((p) => (
                                        <Tab key={p.name} value={p.name} className="font-body text-gray-500 w-fit px-10">
                                            <div className="font-bold">{p.name}</div>
                                            <div className="text-xs">{p.longName}</div>
                                        </Tab>
                                    ))}
                                </TabsHeader>
                                <TabsBody
                                    animate={{
                                        mount: { y: 0 },
                                        unmount: { y: 250 },
                                    }}
                                >
                                    {products.map(p => {
                                        const features = p.product_features;
                                        const images:any[] = !p.images? [] : p.images.map(i => {
                                            const url = i.url;
                                            return url ? `${BACKEND_HOST}${url}` : "";
                                        });
                                        return(
                                            <TabPanel key={p.name} value={p.name} className="font-body text-white grid grid-cols-2 gap-8 px-0">

                                                <Carousel images={images} id={p.name}/>
                                                <div>
                                                    <p>{p.description}</p>
                                                    {
                                                        !features || !features.length? null:
                                                        <Fragment>
                                                            <p className="font-bold">Fitur:</p>
                                                            <ul className="list-disc pl-5">
                                                            {      
                                                                features.map(f => (
                                                                    <li key={f.id}>
                                                                        <div>{f.title}</div>
                                                                        <div>{f.description}</div>
                                                                    </li>
                                                                ))
                                                            }
                                                            </ul>
                                                        </Fragment>
                                                    }
                                                </div>
                                            </TabPanel>
                                        )
                                    })}
                                </TabsBody>
                            </Tabs>
                        }
                    </div>
                </div>
            </div>
            <div className="shape-product-b h-36"/>
        </AnimatePresence>
    )
}