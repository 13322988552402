import { useContext} from "react";
import { AppContext } from "../AppContext";
import { BACKEND_HOST } from "../constants/Env";
export default function Portfolio(){
    const {
        appData,
        appDetailData
    } = useContext(AppContext);
    const {portfolios} = appDetailData;
    const sectionData = appData.sections;
    const sectionTitle = sectionData? sectionData[2].title : "";
    const sectionID = sectionData? sectionData[2].href:"";
    return(
        <div className="p-5 lg:p-10" id={sectionID}>
            <div className="text-4xl font-bold mb-3 text-center">{sectionTitle}</div>
            <div className="mx-auto h-1.5 w-12 bg-black mb-10"/>
            <p className="text-center">
                {/* <ReactMarkdown>{sectionDesc}</ReactMarkdown> */}
            </p>
            {
                !portfolios?null:
                <div className="grid grid-cols-1 lg:grid-cols-3 gap-10">
                    {
                        portfolios.map(p => {
                            const image = p.logo?.url; 
                            return(
                                <div>
                                    <div className="w-20 mx-auto mb-5">
                                        {
                                            !image ? null:
                                            <img src={`${BACKEND_HOST}${image}`} alt="portfolio-logo"/>
                                        }
                                    </div>
                                    <div className="text-center mb-2 font-bold">
                                        {p.title}
                                    </div>
                                    <div className="text-center text-sm">
                                        {p.description}
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            }
        </div>
    )
}