import { useEffect, useRef, useState } from "react";
import { motion } from "framer-motion";

interface ICarouselProps{
  images: string[];
  id:string;
}

export default function Carousel({images, id}:ICarouselProps) {
  const [width, setWidth] = useState<number>(0);
  const [imageSelected, setImageSelected] = useState(images[0]);
  const carousel = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const paddingRigth = 24 * images.length;
    if(carousel.current){
        const { scrollWidth, offsetWidth } = carousel.current;
        setWidth(scrollWidth - offsetWidth + paddingRigth);
    }
  }, [images]);

  return (
    <div>
      <div className="image-selected-wrapper">
        <picture>
          <img src={imageSelected} alt="" />
        </picture>
      </div>

      <div ref={carousel} className="carousel">
        <motion.div
          drag="x"
          dragConstraints={{ right: 0, left: -width }}
          className="carousel-inner"
          key={`carousel-${id}`}
        >
          {images.map((image, index) => (
            <picture
              key={index}
              onClick={() => setImageSelected(images[index])}
              className="carousel-item"
            >
              <img src={image} alt="" className="w-full"/>
            </picture>
          ))}
        </motion.div>
      </div>
    </div>
  );
}
