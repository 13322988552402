import { useContext, useEffect,} from "react";
import '@dotlottie/player-component';
import { AppContext } from "../AppContext";
import { BACKEND_HOST } from "../constants/Env";
import ReactMarkdown from 'react-markdown';
import { useCycle } from "framer-motion";
import { ICarousel } from "../models/carousel";
import { motion, AnimatePresence } from "framer-motion";

const Banner = () => {
    const {
        appData
    } = useContext(AppContext);
    let scenes = appData.carousels ? appData.carousels : [];
    if(scenes.length){
        return <BannerAnimation scenes={scenes}/>
    }
    return <div/>
}

interface IBannerAnimationProps{
    scenes: ICarousel[];
}

const BannerAnimation = ({scenes=[]}:IBannerAnimationProps) => {
    const [currentScene, setCurrentScene] = useCycle(...scenes);
    // const [counter, setCounter] = useState(0); 
    useEffect(() => {
        const timeOut = setTimeout(setCurrentScene, 10000);
        // setCounter(counter+1);
        return () => clearTimeout(timeOut);
    }, [currentScene, setCurrentScene]);
    const lottiePath = currentScene.animation?.url;
    return (
        <AnimatePresence key="banner">
            <div className="mx-auto lg:grid lg:grid-cols-2 lg:gap-5 lg:items-center h-screen min-h-screen max-w-screen-xl pt-5 lg:pt-24" id="about">
                <motion.div
                    key={`${currentScene.id}-lottie`}
                    initial={{ y: "-100%", opacity: 0 }}
                    exit={{ y: "-100%", opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    transition={{ duration: 0.5, ease: "easeInOut" }}
                >
                    {
                        !lottiePath? null:
                        <dotlottie-player
                            src={`${BACKEND_HOST}${lottiePath}`}
                            autoplay
                            loop
                            class="h-[500px]"
                        />
                    }
                </motion.div>
                <motion.h1
                    className="text-3xl lg:text-4xl xl:text-5xl font-medium text-black-600 leading-normal text-center px-5 lg:px-0 lg:text-right"
                    key={currentScene.id}
                    initial={{ y: "100%", opacity: 0 }}
                    exit={{ y: "100%", opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    transition={{ duration: 0.5, ease: "easeInOut" }}
                >
                    <ReactMarkdown>{currentScene.caption}</ReactMarkdown> 
                </motion.h1>
            </div>
            <div className="h-20"/>
        </AnimatePresence>
    );
};

export default Banner;
