import { AppContext, AppProvider,IAppContextValue } from "./AppContext";
import About from "./sections/About";
import Banner from "./sections/Banner";
import Contacts from "./sections/Contacts";
import Header from "./sections/Header";
//import MorePortfolio from "./sections/MorePortfolio";
import Portfolio from "./sections/Portfolio";
import Products from "./sections/Products";
import Whatsapp from "./components/controls/Whatsapp";
import { LoadingOverlay } from "./components/common/Loading";

function App() {
  return (
    <AppProvider>
      <AppContext.Consumer>
      {({
        appData
      }:IAppContextValue)=>(
        <div className="font-body h-screen">
          {
            !appData.about ? <LoadingOverlay/> : null
          }
          <Header/>
          <Banner/>
          <About/>
          <Products/>
          <Portfolio/>
          {/* <MorePortfolio/> */}
          <Contacts/>
          <Whatsapp/>
        </div>
      )}
      </AppContext.Consumer>
    </AppProvider>
  );
}

export default App;
