import { IAccordion } from "../../models/accordion";
import {
    Accordion,
    AccordionHeader,
    AccordionBody,
  } from "@material-tailwind/react";

interface IAccordionProps extends IAccordion{
    id:number;
    openID: string;
    onClick: Function;
}

export default function AccordionList({title, accordion_items, openID, onClick, id}:IAccordionProps) {
    if(!accordion_items || !accordion_items.length){
        return null;
    }
    const getOpenID = (childID?:number) => {
        return `${id}-${childID}`;
    }
    const isActive = (childID?:number) => {
        if(getOpenID(childID) === openID){
            return true;
        }
        return false;
    }
    function Icon({ isActive=false }){
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className={`${
                isActive ? "rotate-180" : "rotate-0"
            } h-5 w-5 transition-transform text-white`}
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth={2}
          >
            <path strokeLinecap="round" strokeLinejoin="round" d="M19 9l-7 7-7-7" />
          </svg>
        );
    }
    return(
        <div className="shadow-xl rounded-2xl p-3 md:p-6 mb-10 bg-cyan-600">
            <p className="text-2xl font-bold mb-3">{title}</p>
            {
                accordion_items.map((ai)=>{
                    const active = isActive(ai.id);
                    return(
                        <Accordion open={active} className="mb-3" icon={<Icon isActive={active}/>} key={ai.id}>
                            <AccordionHeader onClick={() => onClick(getOpenID(ai.id))} className="pb-3 border-b-rose-200">
                                <span className="font-body text-left text-white ">{ai.title}</span>
                            </AccordionHeader>
                            <AccordionBody className="py-3 text-white font-body">
                                {ai.description}
                            </AccordionBody>
                        </Accordion>
                    )
                })
            }
        </div>
    )
}