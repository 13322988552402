import { useContext, useState} from "react";
import { AppContext } from "../AppContext";
import ReactMarkdown from 'react-markdown';
import { BACKEND_HOST } from "../constants/Env";
import AccordionList from "../components/common/Accordion";
import FontAwesome from "../components/common/FontAwesome";
export default function About(){
    const {
        appData,
        appDetailData
    } = useContext(AppContext);
    const [accordionOpenID, setAccordionOpenID] = useState("");
    const sectionData = appData.sections;
    const sectionTitle = sectionData? sectionData[0].title : "";
    // const sectionDesc = sectionData? sectionData[0].description : "";
    const sectionID = sectionData? sectionData[0].href:"";
    const visionData = appData.vision;
    const visionTitle = visionData? visionData.title : "";
    const visionDesc = visionData? visionData.description : "";
    const visionImage = visionData? visionData.image?.url: "";
    const accordions = appDetailData.accordions ? appDetailData.accordions : [];
    const aboutData = appData.about;
    const aboutImage = aboutData ? aboutData.logoBig?.url:"";
    const aboutTagline = aboutData ? aboutData.tagline : "";
    const aboutMail = aboutData? aboutData.email : "";
    return (
        <div>
            <div className="relative">
                <div className="shape-about-t h-36 lg:h-40"/>
                <div className="py-10 px-3 lg:px-10 bg-cyan-700 text-white" id={sectionID}>
                    <p className="text-4xl font-bold mb-3 text-center">{sectionTitle}</p>
                    <div className="mx-auto h-1.5 w-12 bg-white mb-10"/>
                    {/* <p className="text-center pb-10">
                        <ReactMarkdown>{sectionDesc}</ReactMarkdown>
                    </p> */}
                    <div className="text-3xl font-bold mb-3 text-center lg:text-left">{visionTitle}</div>
                    <div className="lg:grid lg:grid-cols-2 lg:gap-5" >
                        <div className="mb-5">
                            <p className="pb-5 text-center lg:text-left">
                                <ReactMarkdown>{visionDesc}</ReactMarkdown>
                            </p>
                            {
                                !visionImage ? null:
                                <img src={`${BACKEND_HOST}${visionImage}`} alt="vision" className="w-3/4 mx-auto lg:w-auto lg:pr-20"/>
                            }
                        </div>
                        <div>
                            {
                                accordions.map((a, index)=> (
                                    <AccordionList accordion_items={a.accordion_items} title={a.title} 
                                        openID={accordionOpenID} key={index} id={index}
                                        onClick={setAccordionOpenID}
                                    />
                                ))
                            }
                        </div>
                    </div>
                </div>
                <div className="shape-about-b w-full h-44"/>
            </div>

            <div className="grid grid-cols-1 lg:grid-cols-2 gap-5 px-3 lg:px-10">
                <div>
                    <div className="text-3xl mb-3 font-semibold">
                        <ReactMarkdown>{aboutTagline}</ReactMarkdown>
                    </div>
                    <div className="flex items-center w-fit hover:text-cyan-700">
                        <FontAwesome name="FaEnvelopeOpen"/>
                        <a className="ml-2" href={`mailto:${aboutMail}`}>{aboutMail}</a>
                    </div>
                </div>
                <div>
                {
                    !aboutImage ? null:
                    <img src={`${BACKEND_HOST}${aboutImage}`} alt="about" className="w-1/2 lg:w-96 mr-auto lg:ml-auto"/>
                }
                </div>
            </div>
        </div>
    )
} 