import { useState, useEffect, useContext } from "react";
// Import react scroll
import { Link as LinkScroll } from "react-scroll";
import { AppContext } from "../AppContext";
import FontAwesome from "../components/common/FontAwesome";
import { BACKEND_HOST } from "../constants/Env";

const Header = () => {
    const {
        appData
    } = useContext(AppContext);
    const [activeLink, setActiveLink] = useState<string>("");
    const [scrollActive, setScrollActive] = useState<boolean>(false);
    const {about} = appData;
    const sectionData = appData.sections;
    const profileID = sectionData? sectionData[0].href : "";
    const productID = sectionData? sectionData[1].href : "";
    const portfolioID = sectionData? sectionData[2].href : "";
    const contactID = sectionData? sectionData[3].href : "";
    useEffect(() => {
        window.addEventListener("scroll", () => {
        setScrollActive(window.scrollY > 20);
        });
    }, []);
    const logoUrl = about?.logoSmall?.url;
    return (
    <>
      <header className = "fixed top-0 w-full  z-30 bg-white">
        <div className={
          "transition-all " + (scrollActive ? " shadow-md pt-0" : " pt-4")
        }>
          <nav className="max-w-screen-xl mx-auto grid grid-flow-col py-2">
            <div className="pl-3 lg:pl-0 flex items-center">
                <div className="h-12 w-auto">
                  {
                    !logoUrl? null:
                    <img src={`${BACKEND_HOST}${logoUrl}`} className="h-full" alt="logo"/>
                  }
                </div>
            </div>
            <ul className="hidden lg:flex text-black  items-center justify-end">
              <LinkScroll
                activeClass="active"
                to={profileID}
                spy={true}
                smooth={true}
                duration={1000}
                onSetActive={() => {
                  setActiveLink(profileID);
                }}
                className={
                  "px-4 py-2 mx-2 cursor-pointer animation-hover inline-block relative" +
                  (activeLink === profileID
                    ? " text-cyan-700 animation-active "
                    : " text-black hover:text-cyan-700 a")
                }
              >
                Profile
              </LinkScroll>
              <LinkScroll
                activeClass="active"
                to={productID}
                spy={true}
                smooth={true}
                duration={1000}
                onSetActive={() => {
                  setActiveLink(productID);
                }}
                className={
                  "px-4 py-2 mx-2 cursor-pointer animation-hover inline-block relative" +
                  (activeLink === productID
                    ? " text-cyan-700 animation-active "
                    : " text-black hover:text-cyan-700 ")
                }
              >
                Product
              </LinkScroll>
              <LinkScroll
                activeClass="active"
                to={portfolioID}
                spy={true}
                smooth={true}
                duration={1000}
                onSetActive={() => {
                  setActiveLink(portfolioID);
                }}
                className={
                  "px-4 py-2 mx-2 cursor-pointer animation-hover inline-block relative" +
                  (activeLink === portfolioID
                    ? " text-cyan-700 animation-active "
                    : " text-black hover:text-cyan-700 ")
                }
              >
                Portfolio
              </LinkScroll>
              <LinkScroll
                activeClass="active"
                to={contactID}
                spy={true}
                smooth={true}
                duration={1000}
                onSetActive={() => {
                  setActiveLink(contactID);
                }}
                className={
                  "px-4 py-2 mx-2 cursor-pointer animation-hover inline-block relative" +
                  (activeLink === contactID
                    ? " text-cyan-700 animation-active "
                    : " text-black hover:text-cyan-700 ")
                }
              >
                Contact
              </LinkScroll>
            </ul>
            {/* <div className="col-start-10 col-end-12 font-medium flex justify-end items-center">
              <a target="_blank" href={about?.link1} className="mr-2">
                <ButtonOutline><p>ReenDoo Network</p></ButtonOutline>
              </a>
              <a target="_blank" href={about?.link2} >
                <ButtonOutline><p>ReenDoo Systems</p></ButtonOutline>
              </a>
            </div> */}
          </nav>
        </div>
      </header>
      {/* Mobile Navigation */}

      <nav className="fixed lg:hidden bottom-0 left-0 right-0 z-30 shadow-t bg-white">
        <div className="">
          <ul className="flex w-full justify-between items-center text-black">
            <LinkScroll
              activeClass="active"
              to={profileID}
              spy={true}
              smooth={true}
              duration={1000}
              onSetActive={() => {
                setActiveLink(profileID);
              }}
              className={
                "mx-1 sm:mx-2 px-3 sm:px-4 py-2 flex flex-col items-center text-xs border-t-2 transition-all " +
                (activeLink === profileID
                  ? "  border-cyan-700 text-cyan-700"
                  : " border-transparent")
              }
            >
              <FontAwesome
               className={`${activeLink === profileID ? "text-cyan-700" : "text-gray-700"} h-5 w-5 mb-1`}
               name="FaSitemap"
              />
              Profile
            </LinkScroll>
            <LinkScroll
              activeClass="active"
              to={productID}
              spy={true}
              smooth={true}
              duration={1000}
              onSetActive={() => {
                setActiveLink(productID);
              }}
              className={
                "mx-1 sm:mx-2 px-3 sm:px-4 py-2 flex flex-col items-center text-xs border-t-2 transition-all " +
                (activeLink === productID
                  ? "  border-cyan-700 text-cyan-700"
                  : " border-transparent ")
              }
            >
              <FontAwesome
               className={`${activeLink === productID ? "text-cyan-700" : "text-gray-700"} h-5 w-5 mb-1`}
               name="FaBoxes"
              />
              Product
            </LinkScroll>
            <LinkScroll
              activeClass="active"
              to={portfolioID}
              spy={true}
              smooth={true}
              duration={1000}
              onSetActive={() => {
                setActiveLink(portfolioID);
              }}
              className={
                "mx-1 sm:mx-2 px-3 sm:px-4 py-2 flex flex-col items-center text-xs border-t-2 transition-all " +
                (activeLink === portfolioID
                  ? "  border-cyan-700 text-cyan-700"
                  : " border-transparent ")
              }
            >
              <FontAwesome
               className={`${activeLink === portfolioID ? "text-cyan-700" : "text-gray-700"} h-5 w-5 mb-1`}
               name="FaScroll"
              />
              Portfolio
            </LinkScroll>
            <LinkScroll
              activeClass="active"
              to={contactID}
              spy={true}
              smooth={true}
              duration={1000}
              onSetActive={() => {
                setActiveLink(contactID);
              }}
              className={
                "mx-1 sm:mx-2 px-3 sm:px-4 py-2 flex flex-col items-center text-xs border-t-2 transition-all " +
                (activeLink === contactID
                  ? "  border-cyan-700 text-cyan-700"
                  : " border-transparent ")
              }
            >
              <FontAwesome
               className={`${activeLink === contactID ? "text-cyan-700" : "text-gray-700"} h-5 w-5 mb-1`}
               name="FaAddressCard"
              />
              Contact
            </LinkScroll>
          </ul>
        </div>
      </nav>
    </>
  );
};

export default Header;
