import { addDays } from "date-fns";
import { createContext, useState } from "react";
import { LOCAL_STORAGE_KEY, DEFAULT_TOKEN } from "./constants/Env";
import { IUsersPermissions, UserPermisisons } from "./models/user";
import {useEffect} from "react";
import { AppData, AppDetailData, IAppData, IAppDetailData } from "./models/appData";

export interface IAppContextValue{
    userAuth?: IUsersPermissions;
    setUserAuth?: Function;
    setUserWithExpiryDate?: Function;
    removeUserAuth?: Function;
    appData: IAppData;
    appDetailData: IAppDetailData;
}
export const AppContext = createContext<IAppContextValue>({appData:AppData(), appDetailData: AppDetailData()});
export const AppProvider = ({children=<div/>}) => {
    const [userAuth, setUserAuth] = useState<IUsersPermissions>(UserPermisisons());
    const [appData, setAppData] = useState<IAppData>(AppData());
    const [appDetailData, setAppDetailData] = useState<IAppDetailData>(AppDetailData());
    const setUserWithExpiryDate = async(_userPermission: IUsersPermissions) => {
        if(!_userPermission){
          return;
        }
        const currentDate = new Date();
        window.token = _userPermission.jwt ? _userPermission.jwt : DEFAULT_TOKEN; //set token to global var
        setUserAuth({
          ..._userPermission,
          expired : addDays(currentDate, 1).getTime().toString() //set expiration login state
        });
    }
    
    const removeUserAuth = () => {
        try {
            // Save to local storage
            window.localStorage.removeItem(LOCAL_STORAGE_KEY);
            setUserAuth(UserPermisisons());
        } catch (error) {
            console.log(error);
        }
    }
    const value: IAppContextValue = {
        userAuth,
        setUserAuth,
        setUserWithExpiryDate,
        removeUserAuth,
        appData,
        appDetailData
    };

    useEffect(()=>{
        const initUserAuth = async () => {
            window.token = userAuth.jwt? userAuth.jwt: DEFAULT_TOKEN;
            if(!userAuth.jwt){
                try {
                    // Get from local storage by key
                    const _userAuth = window.localStorage.getItem(LOCAL_STORAGE_KEY);
                    if(!_userAuth){
                        setUserAuth(UserPermisisons());
                        return;
                    }
                    const _userAuthJson = JSON.parse(_userAuth);
                    if(!_userAuthJson || !_userAuthJson.expired){
                        setUserAuth(UserPermisisons());
                        return;
                    }
                    const currentDate = new Date();
                    const expiryDate = new Date(_userAuthJson.expired);
                    if(expiryDate < currentDate){
                        setUserAuth(UserPermisisons());
                        return;
                    }
                    await setUserWithExpiryDate(_userAuthJson); //set expiration login state
                } catch (error) {
                    console.log(error);
                    setUserAuth(UserPermisisons());
                }
            }else{
                try {
                    // Save to local storage
                    window.localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(userAuth));
                } catch (error) {
                    console.log(error);
                }
            }
        }
        const initAppData = async () => {
            if(appData.about?.email){
                return;
            }
            const _appData:IAppData = {};
            const _appDetailData:IAppDetailData = {};
            _appData.about = {
                logoSmall:{url:"https://ik.imagekit.io/eez9hx5gr/landing/logo-s.png"},
                companyLongName: "CV. ICM Inovasi Indonesia",
                companyShortName: "ICM Inovasi",
                email: "icminovasi@gmail.com",
                link1:"/",
                link2:"/",
                address1: "Jl. Diponegoro, Banyuanyar, Sampang - Jawa Timur, 69216",
                mapUrl:'https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3958.24982583831!2d113.25719887499919!3d-7.212316492793386!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zN8KwMTInNDQuMyJTIDExM8KwMTUnMzUuMiJF!5e0!3m2!1sen!2sid!4v1695865528616!5m2!1sen!2sid%22%20width=%22600%22%20height=%22450%22%20style=%22border:0;%22%20allowfullscreen=%22%22%20loading=%22lazy%22%20referrerpolicy=%22no-referrer-when-downgrade%22',
                phone: "+6281279881542",
                logoBig: {url:"https://ik.imagekit.io/eez9hx5gr/landing/logo.png"},
                whatsapp: "+6281279881542",
                tagline: "Empowering Innovation: Where IT, Creativity, and Mechatronics Converge."
            };
            _appData.carousels = [
                {
                    id:1,
                    caption: "Transforming Concepts into Reality through Technology and Artistry",
                    animation:{url:"https://lottie.host/5c77ee5b-b424-4b02-8315-1e1698b37530/7GYNEzfmaT.lottie"}
                },
                {
                    id:2,
                    caption: "Where Quality Meets Passion",
                    animation: {url:"https://lottie.host/f89a0f11-e2c2-4579-84d0-98122a00442c/MD7kVh15VQ.lottie"}
                },
                {
                    id:3,
                    caption: "Your Vision, Our Innovation: Together, We Shape the Future",
                    animation: {url:"https://lottie.host/c21b1c72-9aed-4433-b668-87e4445c7e52/6Cjw5KKVyR.lottie"}
                },
            ];
            _appData.sections = [
                {
                    href:"profile",
                    title: "About"
                },
                {
                    href:"products",
                    title: "Our Products"
                },
                {
                    href:"portfolio",
                    title: "Our Portfolio"
                },
                {
                    href:"contacts",
                    title: "Contacts"
                }
            ];
            _appData.vision = {
                title: "Our Vision",
                description: "At ICM, we envision a world where the limitless potential of informatics, creativity, and mechatronics seamlessly converge to shape a brighter future. We strive to be the pioneering force that accelerates innovation, transcends boundaries, and transforms industries. We are targetting our business become regional player at 2030.",
                image:{
                    url: "https://ik.imagekit.io/eez9hx5gr/landing/asean.svg"
                }
            }
            _appData.contacts = [
                {
                    name: "Rohimat Zaidi",
                    city: "Sampang",
                    phone: "+6281279881542"
                },
                {
                    name: "Ahmad Baihaki",
                    city: "Pamekasan",
                    phone: "+6282330710007"
                },
                {
                    name: "Imroatus Su'udiyah",
                    city: "Pamekasan",
                    phone: "+6285232305585"
                },
                {
                    name: "Nur Aminatus Solikhah",
                    city: "Mojokerto",
                    phone: "+6281332425913"
                },
                {
                    name: "Peni Sriwahyu Natasari",
                    city: "Malang",
                    phone: "+6287855063917"
                },
            ];
            _appDetailData.accordions = [
                {
                    title: "Mission",
                    accordion_items: [
                        {
                            id:1,
                            title: "Innovating Convergence",
                            description: "We are committed to pioneering the convergence of informatics, creativity, and mechatronics to revolutionize industries and improve lives. Our mission is to develop cutting-edge solutions that seamlessly integrate data-driven insights, artistic expression, and advanced automation to empower businesses and individuals in an ever-evolving digital world."
                        },
                        {
                            id:2,
                            title: " Bridging Imagination with Technology",
                            description: "Our mission is to bridge the realms of imagination and technology by leveraging informatics, creativity, and mechatronics. We aim to inspire creativity while harnessing the power of data and automation to create products and services that enhance human experiences, drive innovation, and solve complex challenges."
                        },
                        {
                            id:3,
                            title:"Engineering Tomorrow's Possibilities",
                            description:"At our core, we are builders of tomorrow's possibilities. Our company is dedicated to harnessing the synergy between informatics, creativity, and mechatronics to engineer transformative solutions. We strive to create a future where data is the canvas, creativity is the brush, and mechatronics are the hands that bring visionary ideas to life, shaping a smarter, more connected world."
                        }
                    ]
                },

            ];
            _appDetailData.products = [
                {
                    name: "Sayur GO",
                    description: "An E-Commerce mobile application built with NodeJS tech stack: Svelte, Strapi and PostgreSQL",
                    images: [
                        {url: "https://ik.imagekit.io/eez9hx5gr/landing/sayurgo-list-admin.PNG"},
                        {url: "https://ik.imagekit.io/eez9hx5gr/landing/sayurgo-detail-cust.PNG"},
                        {url: "https://ik.imagekit.io/eez9hx5gr/landing/sayurgo-login.PNG"},
                    ],
                    longName: "Informatics"
                },
                {
                    name: "Erapi",
                    description: "An ERP with end to end feature (Puchasing, Sales, SCM, and CRM) and has fresh-modern user interface",
                    images: [
                        {url: "https://ik.imagekit.io/eez9hx5gr/landing/erapi-1.png"},
                        {url: "https://ik.imagekit.io/eez9hx5gr/landing/erapi-3.png"},
                        {url: "https://ik.imagekit.io/eez9hx5gr/landing/erapi-2.png"},
                        {url: "https://ik.imagekit.io/eez9hx5gr/landing/erapi-4.png"},
                    ],
                    longName: "Informatics"
                }
            ];
            _appDetailData.portfolios = [
                {
                    title:"GERCEPS: Information System for Stunting Prevention and Mitigation",
                    description: "Stunting, a condition characterized by impaired growth and development in children due to chronic malnutrition and inadequate nutrition, is a significant public health concern worldwide. An Information System for Stunting Prevention and Mitigation is a comprehensive and integrated digital platform designed to address this pressing issue effectively.",
                    logo: {url:"https://ik.imagekit.io/eez9hx5gr/landing/poltera.png"}
                },
                {
                    title:"SIMPBL: Sistem Informasi Manajemen Project Based Learning",
                    description: "Project-Based Learning (PBL) is an innovative and student-centered approach to education that fosters critical thinking, problem-solving, and practical skills development. The Information System for Project-Based Learning Monitoring is a digital platform designed to support educators, administrators, and students in planning, executing, and assessing PBL initiatives effectively.",
                    logo: {url:"https://ik.imagekit.io/eez9hx5gr/landing/poltera.png"}
                },
                {
                    title:"SILABUS: Sistem Informasi Lab dan Bengkel Terpusat",
                    description: "SILABUS is The laboratory inventory management information system. This is a robust and efficient digital solution designed to streamline and optimize the management of laboratory resources, materials, and equipment. Whether in academic, research, or industrial laboratories, LIMS plays a pivotal role in enhancing productivity, ensuring compliance, and maintaining the integrity of experiments and research.",
                    logo: {url:"https://ik.imagekit.io/eez9hx5gr/landing/poltera.png"}
                },
            ];
            setAppData(_appData);
            setAppDetailData(_appDetailData);
        }
        const init = async () => {
            await initUserAuth();
            await initAppData();
        }
        init();
    },[appData, userAuth]);

    return (
        <AppContext.Provider 
            value={value}
        >
            {children}
        </AppContext.Provider>
    );
}