import { IAbout } from "./about";
import { IAccordion } from "./accordion";
import { ICarousel } from "./carousel";
import { IContact } from "./contact";
import { IMorePortfolio } from "./morePortfolio";
import { IPortfolio } from "./portfolio";
import { IProduct } from "./product";
import { ISection} from "./section";
import { IVision } from "./vision";

export interface IAppData{
    about?: IAbout;
    carousels?: ICarousel[];
    sections?: ISection[];
    vision?: IVision;
    contacts?: IContact[];
    products?: IProduct[];
}

export interface IAppDetailData{
    accordions?: IAccordion[];
    products?: IProduct[];
    portfolios?:IPortfolio[];
    morePortfolios?:IMorePortfolio[];
}

export const AppData = ():IAppData => ({
});


export const AppDetailData = ():IAppDetailData => ({
});