import { AppContext } from "../AppContext";
import { useContext} from "react";
import FontAwesome from "../components/common/FontAwesome";

export default function Contacts(){
    const {
        appData,
    } = useContext(AppContext);
    const {about, contacts, sections} = appData;
    const sectionID = sections? sections[3].href:"";
    const addresses = !about ? [] : Object.keys(about).filter(k => k.includes("address")).map(ka=> about[ka]);

    return(
        <div className="text-white">
            <div className="shape-footer-t h-72"/>
            <div className="bg-cyan-700 px-3 lg:px-10 pb-10" id={sectionID}>
                <div className="text-center lg:text-left font-bold text-2xl mb-4">
                    {about?.companyLongName}
                </div>
                <div className="grid grid-cols-1 lg:grid-cols-2 lg:gap-10">
                    <div>
                        {
                            addresses.map(a => (
                            <div className="mb-3 text-center lg:text-left ">
                                {a}
                            </div>
                            ))
                        }
                        <div className="flex items-center w-fit hover:text-cyan-200 py-3 mx-auto lg:w-full">
                            <FontAwesome name="FaEnvelopeOpen"/>
                            <a className="ml-2" href={`mailto:${about?.email}`}>{about?.email}</a>
                        </div>
                        <div className="pb-20 pt-5 h-full">
                            <iframe src={about?.mapUrl}
                                allowFullScreen={false}
                                loading="lazy"
                                className="w-full h-full"
                                title="company-map"
                            ></iframe>
                        </div>
                    </div>
                    <div>
                        <div className="text-xl font-bold mb-10 mt-10 lg:mt-0">
                            Workshop & Supports
                        </div>
                        <div className="grid grid-cols-2 gap-10">
                        {
                            !contacts ? null:
                            contacts.map(c => (
                                <div className="text-sm  border-white">
                                    <div className="border-l-4 pl-4">
                                        <div className="text-lg mb-2">
                                            {c.city}
                                        </div>
                                        <div className="mb-2">
                                            <em>{c.name}</em>
                                        </div>
                                    </div>
                                    <div className="pl-4">
                                        <a className="mb-2 hover:text-cyan-200" href={`tel:${c.phone}`}>
                                            {c.phone}
                                        </a>
                                        <div>
                                            {c.address}
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                        </div>
                    </div>
                </div>
                <div className="text-center lg:text-left py-20">
                    {`Copyright © ${new Date().getFullYear()} ${about?.companyLongName}`}
                </div>
            </div>
        </div>
    )
}