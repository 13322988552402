export interface IUsersPermissionsMeRole {
    id: number;
    name: string;
    description?: string;
    type?: string;
}

export interface IUsersPermissionsMe {
    id?: number;
    username: string;
    email?: string;
    confirmed?: boolean;
    blocked?: boolean;
    role?: IUsersPermissionsMeRole;
}
  
export interface IUsersPermissions {
    jwt: string;
    user: IUsersPermissionsMe;
    expired?: string;
}

export const UsersPermissionsMe = ():IUsersPermissionsMe => {
    return {
        username:"",
    }
}

export const UserPermisisons = ():IUsersPermissions => {
    return {
        jwt:"",
        user:UsersPermissionsMe(),
        expired:""
    }
}

export interface IUsersPermissionsRegisterInput {
    username: string;
    email: string;
    password: string;
}
  
export interface IUsersPermissionsLoginInput {
    identifier: string;
    password: string;
    provider: string;
}



export interface IUsersPermissionsPasswordResponse {
    ok: boolean;
}
  
  
export interface IUsersPermissionsCreateRoleResponse {
    ok: boolean;
}
  
export interface IUsersPermissionsUpdateRoleResponse {
    ok: boolean;
}
  
export interface IUsersPermissionsDeleteRoleResponse {
    ok: boolean;
}
  